import { Space, Typography } from "antd";
import documents_step1 from "../../assets/documents_step1.png"
import documents_step2 from "../../assets/documents_step2.png"
import documents_step3 from "../../assets/documents_step3.png"
import { Card, H1, H3 } from "../../commonStyled";
import { Line } from "./styled";

export function SigningSteps() {
    return <Space direction='vertical' size={32}>
        <H1>Этапы подписания договора</H1>
        <Space size={24}>
            <Card style={{width: '384px', padding: '32px'}}> 
                <Space direction='vertical' size={24} align="center">
                    <img src={documents_step1}/>
                    <Line style={{width: '320px'}}/>
                    <Space direction='vertical' size={16} style={{width: '320px'}}>
                        <H3>1. Документ не подписан</H3>
                        <Typography.Text>Без подписанного договора макеты нельзя отправить на регистрацию</Typography.Text>
                    </Space>
                </Space>
            </Card>
            <Card style={{width: '384px', padding: '32px'}}> 
                <Space direction='vertical' size={24} align="center">
                    <img src={documents_step2}/>
                    <Line style={{width: '320px'}}/>
                    <Space direction='vertical' size={16} style={{width: '320px'}}>
                        <H3>2. Связь с поддержкой</H3>
                        <Typography.Text>Утвержаются способ, дата и время <br/>для подписания договора</Typography.Text>
                    </Space>
                </Space>
            </Card>
            <Card style={{width: '384px', padding: '32px'}}> 
                <Space direction='vertical' size={24} align="center">
                    <img src={documents_step3}/>
                    <Line style={{width: '320px'}}/>
                    <Space direction='vertical' size={16} style={{width: '320px'}}>
                        <H3>3. Документ подписан</H3>
                        <Typography.Text>После успешного подписания договора макеты можно зарегистрировать</Typography.Text>
                    </Space>
                </Space>
            </Card>
        </Space>
    </Space>
}