import { Button, Typography } from "antd";
import styled from "styled-components";

export const H1 = styled(Typography.Text)`
font-size: 38px;
font-weight: 500;
`
export const H2 = styled(Typography.Text)`
font-size: 30px;
font-weight: 500;
line-height: 38px;
`
export const H3 = styled(Typography.Text)`
font-size: 24px;
font-weight: 500;
`

export const Card = styled.div`
border-radius: 16px;
border: 1px solid var(--colorBorder, rgba(0, 0, 0, 0.15));
background: var(--colorFillAlter, rgba(0, 0, 0, 0.02));
padding: 24px;
`

export const BlackButton = styled(Button)`
background-color: rgba(0, 0, 0, 0.88);
box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
border: 0;
color: #ffff;
&:hover {
    background-color: rgba(0, 0, 0, 0.65) !important;
    color: #ffff !important;
}
&:active {
    background-color: rgb(0, 0, 0) !important;
    color: #ffff !important;
}
`
export const TransparentButton = styled(Button)`
background-color: transparent;
border-radius: 8px;
border: 1px solid rgba(0, 0, 0, 0.88);
box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
color: rgba(0, 0, 0, 0.88);

&:hover {
    color: #1677FF !important;
}
&:active {
    color: #0958D9 !important;
}
`
