import { Button, Flex, Space, Typography } from "antd";
import LogoTelegram from '../../assets/logoTelegram.png'
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export function TemplateEmptyState() {
    const navigate = useNavigate()

    return <Flex vertical align="center">
        <div style={{ height: 72 }}></div>
        <Space size={40} direction="vertical" align="center">
            <img src={LogoTelegram}></img>
            <Space size={8} direction="vertical">
                <Typography.Title level={5} style={{ textAlign: 'center', marginTop: 0 }}>У вас пока нет ни одного макета</Typography.Title>
                <Typography.Text style={{ textAlign: 'center' }}>Создайте первый макет и отправьте его на модерацию</Typography.Text>
            </Space>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => navigate(`/templates/create`)}>
                Добавить макет
            </Button>
        </Space>
    </Flex>
}