import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { keycloak } from './keycloack'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


keycloak.init({
  onLoad: 'check-sso',
}).then(async (authenticated) => {
  console.log(authenticated)
  if (!authenticated) {
    window.location.href = keycloak.createLoginUrl()
  } else {


    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }
})


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
