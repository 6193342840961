import { Wrapper } from "./styled";
import { useEffect, useState } from "react";
import ServiceDescription from "./ServiceDescription";
import { Button, Flex, Space, Typography } from "antd";
import documents from '../../assets/documents.png'
import templates from '../../assets/templates.png'
import { useNavigate } from "react-router";
import { SigningSteps } from "../../components/SigningSteps/SigningSteps";
import { TemplateRegistrationSteps } from "../../components/TemplateRegistrationSteps/TemplateRegistrationSteps";
import { H1, TransparentButton } from "../../commonStyled";

export default function MainPage() {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
      resizeWindow();
      window.addEventListener("resize", resizeWindow);
      return () => window.removeEventListener("resize", resizeWindow);
    }, []);
    
    const resizeWindow = () => {
        setWindowWidth(window.innerWidth);
      };

    return <Wrapper direction='vertical' size={64}>
        <ServiceDescription height={Math.round(windowWidth * 0.4)} />
        <Space size={32}>
            <img src={documents} style={{borderRadius: '32px'}}></img>
            <Space size={32} direction='vertical'>
                <H1>Документы</H1>
                <Flex vertical>
                    <Typography.Text style={{marginBottom: '24px'}}>Справочно-правовой раздел, где есть всё необходимое для вашей работы:</Typography.Text>
                    <Space size={8} style={{marginBottom: '12px'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                            <circle cx="4" cy="4" r="4" fill="#1677FF"/>
                        </svg>
                        <Typography.Text>Быстрый доступ ко всем правилам и инструкциям сервиса</Typography.Text>
                    </Space>
                    <Space size={8}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                            <circle cx="4" cy="4" r="4" fill="#1677FF"/>
                        </svg>
                        <Typography.Text>Отслеживание статуса подписания договора</Typography.Text>
                    </Space>
                </Flex>
                <Button type='primary' size="large" onClick={() => navigate(`/documents`)} style={{padding: '4px 24px'}}>В документы</Button>
            </Space>
        </Space>
        <SigningSteps />
        <Space size={32}>
            <Space size={32} direction='vertical'>
                <H1>Макеты</H1>
                <Space size={24} direction='vertical'>
                    <Typography.Text>Создавайте и регистрируйте макеты объявлений. Просматривайте статусы<br />уже созданных.</Typography.Text>
                    <Typography.Text>Запустите рекламу за пару шагов:</Typography.Text>
                    <Space size={12} direction='vertical'>
                        <Space size={8}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                <circle cx="4" cy="4" r="4" fill="#1677FF"/>
                            </svg>
                            <Typography.Text>Заполните все необходимые данные рекламного объявления</Typography.Text>
                        </Space>
                        <Space size={8}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                <circle cx="4" cy="4" r="4" fill="#1677FF"/>
                            </svg>
                            <Typography.Text>Сверьте внешний вид в предпросмотре</Typography.Text>
                        </Space>
                        <Space size={8}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                <circle cx="4" cy="4" r="4" fill="#1677FF"/>
                            </svg>
                            <Typography.Text>Сохраните макет как черновик или сразу отправьте его на регистрацию</Typography.Text>
                        </Space>
                    </Space>
                </Space>
                <Space size={16}>
                    <Button type='primary' size="large" onClick={() => navigate(`/templates/create`)} style={{padding: '4px 24px'}}>Создать макет</Button>
                    <TransparentButton size="large" onClick={() => navigate(`/templates/create`)} style={{padding: '4px 24px'}}>К макетам</TransparentButton>
                </Space>
            </Space>
            <img src={templates} style={{borderRadius: '32px'}}></img>
        </Space>
        <TemplateRegistrationSteps />
    </Wrapper>
    
}