import { DownOutlined, EditFilled } from "@ant-design/icons";
import { Button, Dropdown, Input, Space, Table, Typography } from "antd";
import { useState } from "react";
import { api } from "../../api";

export function Accounts({ items, update }: { items: any[], update(): void }) {

    const [editItem, setEditItem] = useState('');
    const [editedValue, setEditedValue] = useState('');


    const columns = [
        {
            title: 'Аккаунт',
            dataIndex: 'accountId',
        },
        {
            title: 'Юрлицо',
            dataIndex: 'legalName',
            key: 'legalName',
        },
        {
            title: 'Расположение',
            key: 'location',
            render: (_: string, record: any ) => <span>
                {record.country}<br/>
                {record.city}
            </span>
        },
        {
            title: 'Контакты',
            key: 'contacts',
            render: (_: string, record: any ) => <span>
            {record.phoneNumber}<br/>
            {record.email}
            </span>
        },
        {
            title: 'ИФ сотрудника',
            dataIndex: 'fullName',
        },
        {
            title: 'МТС ID',
            key: 'mtsId',
            width: '200px',
            render: (_: string, record: any ) => editItem !== record.accountId ? <Space>
            {record.clientId}<Button onClick={() => { setEditItem(record.accountId); setEditedValue(record.clientId) }} type="text" color="#1677FF" icon={<EditFilled></EditFilled>}></Button></Space>
            : <Space>
                <Input value={editedValue} onChange={(event) => setEditedValue(event.target.value)}></Input>
                <Button type="text" onClick={() => onUpdateIdHandler(record)}>OK</Button>
            </Space>,
        },
        {
            title: 'Договор',
            key: 'contractState',
            width: '200px',
            render: (_: string, record: any ) => <Dropdown menu={{ onClick: (item: any) => handleMenuClick(record, item.key), items: [{ key: 0, label: 'Не подписан' }, { key: 1, label: 'Подписан' }] }}>
                <Typography.Link>
                    <Space>
                        {['Не подписан', 'Подписан'][record.contractState]}
                        <DownOutlined />
                    </Space>
                </Typography.Link>
            </Dropdown>
        },



    ];

    async function handleMenuClick(item: any, key: number) {
        await updateAccount(item.accountId, key, item.clientId)
        update();
    }

    async function updateAccount(id: string, state: number, clientId: string) {
        await api.updateAccount({
            "accountId": id,
            "state": state,
            "clientId": clientId
        })
    }


    async function onUpdateIdHandler(el: any) {
        await updateAccount(el.accountId, el.contractState, editedValue)
        setEditItem('')
        update();
    }

    return <>
        <Table style={{border: '1px solid rgba(0,0,0,0.15)', borderRadius: '8px'}} 
        dataSource={items.map((el) => ({key: el.accountId, ...el}))} 
        columns={columns} pagination={{position:["topCenter", "bottomCenter"]}}/>
    </>
}