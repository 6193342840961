import { Button, Form, Input } from "antd";
import Title from "antd/es/typography/Title";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
// import { useState } from "react";
import { StateCircle } from "./styled";
// import { api } from "../../api";
import { IUser } from './../../App';

export function Settings (props: {user: IUser}) {
    // const [user, setUser] = useState(props.user);
    const {user} = props;
    const {contactApproved} = user;

    
    // const onFinish = async (formdata: any) => {  // при обновлении данных юзера нужно менять state у App, для корректного отображения email в Header.
    // await api.updateUser({...user, ...formdata});
    // setUser({...user, ...formdata})
    // };
    return  <div style={{margin: 'auto', width: '40%'}}>
            <Title level={3}>Ваш аккаунт</Title>
            <Form name="nest-messages"
            initialValues={user}
            // onFinish={onFinish}
            >
                <Form.Item>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <StateCircle $success = {contactApproved}/>
                        <Title level={5} style={{margin: '8px'}}>{contactApproved ? 'Договор подписан' : 'Договор не подписан'}</Title>
                    </div>
                    
                </Form.Item>        
                <Form.Item name="username" >
                    <Input prefix={<UserOutlined />} disabled/>
                </Form.Item>
                <Form.Item name="email" rules={[{ required: true, message: 'Поле не должно быть пустым.' }]}>
                    <Input prefix={<MailOutlined />} disabled/>
                </Form.Item>
                <Form.Item name="fullName" rules={[{ required: true, message: 'Поле не должно быть пустым.' }]}>
                    <Input prefix={<UserOutlined />} disabled/>
                </Form.Item>

                <Form.Item >
                <Button type="primary" htmlType="submit" block disabled>
                    Обновить данные
                </Button>
                </Form.Item>
            </Form>
        </div>
}