import styled from "styled-components";

export const PhoneWrapper = styled.div`
    position: relative;
    width: 600px;
    padding: 48px;
`

export const Screen = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    position: absolute;
    top: 186px;
    left: 147px;
    height: 498px;
    width: 270px;
    overflow-y: auto;
    border-bottom-left-radius: 29px;
    border-bottom-right-radius: 29px;
`

export const MessageBox = styled.div`
    position: relative;
    margin: 8px 0 16px 14px;
    padding: 12px;
    width: 222px;
    background: #EAECF0;
    border-radius: 15px;
    border-bottom-left-radius: 0;

    color: '#1D2939';
    transition: all 0.5s;
    & > svg {
        position: absolute;
        bottom: -1px;
        left: -5px;
        z-index: 0;

        & > path {
            fill:   '#EAECF0';
            transition: all 0.5s;
        }
    }
    & > div {
        position: relative;
        z-index: 1;
    }
`
export const SenderSection = styled.div`

`

export const DefaultText = styled.div`
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #98a2b3;
    z-index: 1;
`