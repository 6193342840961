import { Flex, Space, Typography } from "antd";
import { Card, H1 } from "../../commonStyled";

export function TemplateRegistrationSteps() {
    return <Space direction='vertical' size={32}>
        <H1>Этапы регистрации макета</H1>
        <Flex align="center">
            <Card style={{width: '276px'}}>
                <Flex align='center' style={{marginBottom: '8px'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <path d="M6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.3137 0 12 2.68629 12 6C12 9.31371 9.3137 12 6 12Z" fill="black" fillOpacity="0.45"/>
                    </svg>
                    <Typography.Text style={{marginLeft: '8px', fontWeight: 500}}>Черновик</Typography.Text>
                </Flex>
                <Typography.Text style={{color: 'rgba(0, 0, 0, 0.65)'}}>Макет создан и сохранен как черновиквик</Typography.Text>
            </Card>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="10" viewBox="0 0 32 10" fill="none">
                <path d="M32 5L25 0.958548V9.04145L32 5ZM0 5.7L25.7 5.7V4.3L0 4.3L0 5.7Z" fill="#D9D9D9"/>
            </svg>
            <Card style={{width: '276px'}}>
                <Flex align='center' style={{marginBottom: '8px'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <path d="M6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.3137 0 12 2.68629 12 6C12 9.31371 9.3137 12 6 12Z" fill="#FAAD14"/>
                    </svg>
                    <Typography.Text style={{marginLeft: '8px', fontWeight: 500}}>Ожидает отправки</Typography.Text>
                </Flex>
                <Typography.Text style={{color: 'rgba(0, 0, 0, 0.65)'}}>Макет зафиксирован и готовится к отправке</Typography.Text>
            </Card>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="10" viewBox="0 0 32 10" fill="none">
                <path d="M32 5L25 0.958548V9.04145L32 5ZM0 5.7L25.7 5.7V4.3L0 4.3L0 5.7Z" fill="#D9D9D9"/>
            </svg>
            <Card style={{width: '276px'}}>
                <Flex align='center' style={{marginBottom: '8px'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <path d="M6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.3137 0 12 2.68629 12 6C12 9.31371 9.3137 12 6 12Z" fill="#1677FF"/>
                    </svg>
                    <Typography.Text style={{marginLeft: '8px', fontWeight: 500}}>Отправлен</Typography.Text>
                </Flex>
                <Typography.Text style={{color: 'rgba(0, 0, 0, 0.65)'}}>Макет заполнен и отправлен на модерацию</Typography.Text>
            </Card>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="138" viewBox="0 0 32 138" fill="none">
                <path d="M32 5L25 0.958548V9.04145L32 5ZM16.7 52.9957L16.7 17.0053H15.3L15.3 52.9957H16.7ZM16.7 17.0053C16.7 10.7641 21.7596 5.7 28 5.7V4.3C20.9856 4.3 15.3 9.99167 15.3 17.0053H16.7ZM15.3 52.9957C15.3 61.4459 8.44971 68.3 0 68.3L0 69.7C9.2234 69.7 16.7 62.2186 16.7 52.9957H15.3Z" fill="#D9D9D9"/>
                <path d="M32 133L25 137.041V128.959L32 133ZM16.7 85L16.7 121H15.3L15.3 85H16.7ZM16.7 121C16.7 127.241 21.7592 132.3 28 132.3V133.7C20.986 133.7 15.3 128.014 15.3 121H16.7ZM15.3 85C15.3 76.55 8.44996 69.7 0 69.7L0 68.3C9.22316 68.3 16.7 75.7768 16.7 85H15.3Z" fill="#D9D9D9"/>
            </svg>
            <Space direction="vertical" size={24}>
                <Card style={{width: '276px'}}>
                    <Flex align='center' style={{marginBottom: '8px'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.3137 0 12 2.68629 12 6C12 9.31371 9.3137 12 6 12Z" fill="#52C41A"/>
                        </svg>
                        <Typography.Text style={{marginLeft: '8px', fontWeight: 500}}>Согласован</Typography.Text>
                    </Flex>
                    <Typography.Text style={{color: 'rgba(0, 0, 0, 0.65)'}}>Макет прошел модерацию</Typography.Text>
                </Card>
                <Card style={{width: '276px'}}>
                    <Flex align='center' style={{marginBottom: '8px'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.3137 0 12 2.68629 12 6C12 9.31371 9.3137 12 6 12Z" fill="#F5222D"/>
                        </svg>
                        <Typography.Text style={{marginLeft: '8px', fontWeight: 500}}>Отклонен</Typography.Text>
                    </Flex>
                    <Typography.Text style={{color: 'rgba(0, 0, 0, 0.65)'}}>Макет не прошел модерацию</Typography.Text>
                </Card>
            </Space>
        </Flex>
    </Space>
}