import { Typography } from "antd";

export function FirstTab() {

    return <>
        <Typography.Text>
            🚫 Политические партии или деятели<br></br>
            <br></br>
            🚫 Азартные игры, казино, гэмблинг, прогнозы спортивных матчей, лотереи, тотализаторы<br></br>
            <br></br>
            🚫 Вредные финансовые продукты или услуги<br></br>
            Микрозаймы, финансовые пирамиды
            <br></br>
            🚫 Наркотики, табак (в том числе электронные сигареты), алкоголь<br></br>
            <br></br>
            ✅ Фастфуд и рестораны (с августа 2023)<br></br>
            <br></br>
            🚫 Оружие, боеприпасы, взрывчатые вещества<br></br>
            <br></br>
            🚫 Что-то сомнительное и незаконное<br></br>
            Поддельные документы, контрафактные товары, нелегальные сервисы и услуги<br></br>
            <br></br>
            🚫 Спам-программы, вредоносное ПО, взлом, накрутка подписчиков, генерация автоматического трафика, автоматизированные рассылки, фишинг, похищение информации<br></br>
            <br></br>
            🚫 Товары и услуги 18+<br></br>
            Знакомства, интимные услуги, товары и развлечения для взрослых, любые материалы откровенно интимного характера<br></br>
            <br></br>
            🚫 Товары, связанные с прерыванием беременности/фертильностью<br></br>
            <br></br>
            🔶 Медицинские услуги, лекарства<br></br>
            Для продвижения необходимы лицензии и сертификаты на услуги/препараты<br></br>
        </Typography.Text>
    </>
}