import { Typography } from "antd";

export function ThirdTab() {

    return <>
        <Typography.Text>
            Telegram-канал<br></br>
            🚫 Закрытый канал<br></br>
            Система не пропустит ссылку на закрытый канал. Канал для продвижения должен быть доступен всем пользователям<br></br>
            🚫 Пустой, неактивный канал без описания и постов<br></br>
            В канале должны быть регулярное размещение постов и не менее 10 подписчиков<br></br>
            🔶 Эмоджи в названии канала<br></br>
            Ограничение в кол-ве и актуальности: эмоджи молний, акцент пальцем, восклицательные знаки (все что привлекает внимание)<br></br>
            🔶 Название канала состоит из заглавных букв<br></br>
            Заглавные буквы можно использовать, если они являются частью наименования вашего бренда. В остальных случаях такое написание использовать нельзя.<br></br>
            ✅ Открытый канал<br></br>
            Развлекательный, экспертный или информационный<br></br>
            <br></br>
            Посты в канале<br></br>
            🚫 Кликбейт и недопустимые рекламные тактики<br></br>
            Кликбейт в Telegram запрещен, как и любые методы дополнительного воздействия на желания пользователя совершить какое-то действие. Избегайте всего, что неоправданно обращает на себя внимание: написания слов и предложений заглавными буквами, неоправданного и излишнего выделения текста жирным и курсивом (речь именно о неоправданном форматировании, заголовки в посте вполне можно выделить), чрезмерного количества эмоджи.<br></br>
            🚫 Посты содержат информацию о запрещенных товарах и услугах или недопустимый контент.<br></br>
            См. раздел “Бизнес, товары, услуги” и “Содержание”<br></br>
            🚫 Канал с рекламными постами<br></br>
            Telegram не пропускает каналы, которые используются только или в большей степени для показа рекламных постов.<br></br>
            🔶 Посты с внешними ссылками<br></br>
            Использовать в постах ссылки, которые ведут на сайт или на другую посадочную вне Telegram, не запрещено. Если их кол-во в одном посте не превышает 1 единицы или же если они не соответствуют каналу или рекламному сообщению<br></br>
            ✅ Канал с качественным контентом<br></br>
            Telegram принимает к продвижению контент высокого качества. Контент должен быть интересным для пользователя: развлекательный, образовательный, экспертный.<br></br>
            <br></br>
            Бот<br></br>
            🚫 Бот не запускается<br></br>
            Убедитесь, что /start отрабатывает корректно<br></br>
            🚫 Не заполнено описание бота<br></br>
            Обязательно заполните поле «Что умеет этот бот», которое пользователь видит сразу после перехода.<br></br>
            🚫 Бот создан только для сбора лидов<br></br>
            В ботах нельзя публиковать только рекламу – сборщики заявок и данных пользователей (имя, номер телефона, электронный адрес) не пройдут. Должна быть также внутри полезная информация для пользователя, кроме рекламы.<br></br>
            Пример: бот в формате вопрос-ответ с выбором ответа в каждом вопросе и внешняя ссылка в конце. Назначение такого бота, скорее всего, получить контакт пользователя, а не поделиться с ним пользой. Лучше, если в боте будет сценарий: интерактив с пользователем, вопросы с вводом пользовательского ответа или вопросы с выбором.<br></br>
            🚫 Push-боты<br></br>
            Запрещены боты, которые присылают вопросы, ответ пользователя на которые не играет никакой роли (не развивается сценарий в зависимости от ответа). В таких ботах в конце обычно содержится внешняя ссылка (перенаправляет пользователя куда-то, например, на вебинар, в канал, на сайт, загрузку приложения и т.д) или просьба оставить номер телефона. Все эти функции можно без проблем реализовать внутри бота (включая платежи) в подходящем месте и успешно рекламироваться.<br></br>
            🚫 Функциональность бота неоправданно зависит от подписки на канал или номера телефона<br></br>
            Полная или частичная функциональность бота не должна зависеть от того, подписан ли пользователь на связанный канал или поделился ли своим телефоном. Есть исключения: если вы рекламируете сервис, который обязан верифицировать клиента перед началом диалога, то запросить верификацию, например, по номеру телефона, – обоснованный и оправданный запрос.<br></br>
            🚫 Навязчивые сообщения и спам<br></br>
            Сразу после запуска отправляет множество сообщений с призывом к действию.<br></br>
            Другой плохой пример – навязчивые повторяющиеся рассылки с привязкой ко времени (приглашение на вебинар с огромным количеством КАПСА и восклицательных знаков).<br></br>
            🚫 Бот содержит информацию о запрещенных товарах и услугах или недопустимый контент<br></br>
            См. раздел “Бизнес, товары, услуги” и “Содержание”<br></br>
            🚫 Не соблюдены требования к текстам<br></br>
            Использовано избыточное количество эмоджи, неоправданное написание слов и предложений заглавными буквами для привлечения внимания (полный список см. в разделе “Тексты объявлений”).<br></br>
            🔶 Внешние ссылки в боте<br></br>
            Использовать ссылки, которые ведут на сайт или на другую посадочную вне Telegram, не запрещено. При этом контент бота должен быть полезным прямо для пользователя. Модерация проверит качество бота, оценит его функционал и наполненность внешними ссылками. Если использование ссылок не оправдано рекламу могут не пропустить.<br></br>
            🔶 Обращение к пользователю на «Ты»<br></br>
            Излишняя фамильярность не приветствуется – общайтесь с аудиторией уважительно, на «Вы». Но если ваш бот не пропустили, ищите причину серьёзнее – обычно только по такой причине боты не отклоняют на модерации.<br></br>
            ✅ Полезный или экспертный контент<br></br>
            То, что будет интересно пользователю. Например, PDF с чек-листами или полезными статьями.<br></br>
            ✅ Функциональные интерактивные боты с разветвленным сценарием<br></br>
            Примеры хороших ботов можно найти в памятке Telegram<br></br>
        </Typography.Text>
    </>
}