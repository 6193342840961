import { Typography } from "antd";

export function FourthTab() {

    return <>
        <Typography.Text>
            Тексты объявлений<br></br>
            <br></br>
            ✅ До 160 символов<br></br>
            С учетом пробелов и знаков препинания<br></br>
            ✅ Заголовок до 40 символов<br></br>
            С учетом пробелов и знаков препинания<br></br>
            ✅ Текст без ошибок (в т.ч. пунктуация)<br></br>
            Воспользуйтесь сервисами или встроенными в текстовые программы функциями для проверки грамматических и орфографических ошибок<br></br>
            <br></br>
            🔶 Эмоджи в тексте<br></br>
            👆Подумайте, оправдано ли использование. Если нет, то лучше отказаться 🤫<br></br>
            Эмоджи возможны к размещению, если они загружены через бот. Эмоджи как значки использовать в тексте можно, но в меру: в большом количестве и как 👉избыточный акцент👈 – нельзя. Рекомендуем ставить не более одного эмоджи в объявлении и не использовать их для привлечения внимания 👌<br></br>
            🔶 Слова на английском language<br></br>
            Подойдут общеизвестные слова. Заимствованные слова, понятные только определенной части аудитории (например, по критерию профессии) и нецензурные – не пройдут<br></br>
            🔶 Восклицательные знаки !!!<br></br>
            Нельзя использовать восклицательные в призывах к действию или несколько восклицательных знаков подряд. Если поставили один, но можно было написать текст без него, модерация может отклонить объявление, попробуйте другой элемент привлечения внимания или поставьте точку. Пользователь, скорее всего, и так отреагирует на ваше предложение, если оно ему интересно.<br></br>
            🚫 Ссылка в тексте объявления<br></br>
            Ссылки (например, на сайт) в тексте объявления использовать не разрешено. Допустимо только упомянуть рекламируемый канал. Совет: экономьте символы и не используйте ссылку в формате https://t. me/channel. Ссылки на канал работают без https://, а это целых 8 символов объявления.<br></br>
            🚫 Обращение к пользователю на «Ты»<br></br>
            Излишняя фамильярность не приветствуется – общайтесь с аудиторией уважительно, на «Вы».<br></br>
            🚫 Слова и предложения ЗАГЛАВНЫМИ БУКВАМИ<br></br>
            Исключение – написание вашего бренда, которое постоянно используется в рекламе.<br></br>
            🚫 Неизвестные сокращения и аббревиатуры<br></br>
            Они могут вводить в заблуждение, а в Telegram нужно общаться с пользователем на понятном ему языке. Допустимы общеизвестные и понятные аббревиатуры и сокращения (USD, вуз).<br></br>
            🚫 Лишние символы между букв для привлечения внимания<br></br>
            Например, п р о б е л ы или з,а,п,я,т,ы,е, или что-то ТаКоЕ.<br></br>
            🚫 Формулировки, вводящие в заблуждение<br></br>
            Кликбейт для привлечения внимания, за которым нет реальных фактов, использовать нельзя. Например: «Вы выиграли приз», «Поздравляем, вы стали 1000-ым подписчиком».<br></br>
            🚫 Незаконченные формулировки с призывом кликнуть<br></br>
            Не подойдут формулировки в стиле фишинговых объявлений и тизерные сетей. Например: “Стать миллионером просто. Нужно только...” – а остальное доступно по клику на кнопку; "Наши клиенты масштабируют бизнес и удваивают доход, используя ... УЗНАТЬ ЧТО”.<br></br>
            🚫 Содержание объявления не соответствует содержанию канала<br></br>
            Что обещали пользователю в объявлении, то он должен увидеть и в канале/боте. Реклама с ложными обещаниями не пройдёт.<br></br>
            🚫 Превосходная степень наречий и прилагательных<br></br>
            Если они создают необоснованные заявления, дают обещания, гарантии Например: “Наш уникальный продукт”, “Эксклюзивные условия для вас”, “Лучший в своем роде”, “Изменит жизнь навсегда”, “Никогда не будет лишним”, “Самые высокие доходы”, “Лучшая компания”.<br></br>
            🚫 Многократное повторение одних и тех же слов и фраз<br></br>
            Еще раз: запрещено многократное повторение одних и тех же слов и фраз<br></br>
            🚫 Повелительное наклонение<br></br>
            Привычные для формулировки CTA глаголы в повелительном наклонении (“подпишись”, “перейди” “купи” и т.д.) не подойдут для объявлений в Telegram.<br></br>
            🚫 Просторечия, жаргонизмы<br></br>
            Для части аудитории они могут быть непонятными и введут в заблуждение<br></br>
            🚫 *** лексика, нецензурные выражения, брань<br></br>
            Скрыть звездочками не поможет – ругаться в любом виде и на любом языке запрещено<br></br>
            🚫 Обратить внимание на смысл текста<br></br>
            Не должно быть в объявлении 2–3 не связанных между собой предложения, соответственно из текста совершенно непонятно, что хотят рекламировать. Отредактируйте объявление, чтобы появился смысл, что вы хотите донести до пользователя.<br></br>
            Например: На вынос и с доставкой. Чат поддержки доступен 24/7. Новые скидки каждый день.<br></br>
            🚫 Лишние пробелы<br></br>
            Проверьте, не закрался ли двойной пробел по ошибке<br></br>
            🚫 Тире вместо дефиса<br></br>
            Знак дефиса (“-”) используется для разделения в словах (ковер- самолет). Знак препинания в предложениях – это тире ( “–”). Например: в сообщении “Какую машину купить в 2022 году - рассказываем в канале @channel” “-” нужно заменить на “–”.<br></br>
            🚫 Закодированные символы<br></br>
            Неважно, Unicode или ASCII, кодировки применять нельзя<br></br>
            🔶 Специальные символы<br></br>
            В том числе "/", "\", "|", если вы привыкли использовать их для разделения. Иногда могут допускаться, если оправданы<br></br>
            🚫 Маркерные списки, разрывы строк, нумерация<br></br>
            Нужно уложиться в 160 символов без специальных разделений<br></br>
        </Typography.Text>
    </>
}