import { Layout, Menu, Flex, Typography, Space, Button } from 'antd';
import { IProps } from './typed';
import { TextButton} from './styled';
import { useLocation, useNavigate } from "react-router-dom";
import { keycloak } from '../../keycloack';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useEffect } from 'react';


const { Header, Content, Footer } = Layout;

export function MainLayout({ children, isAdmin }: IProps) {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        window.scrollTo(0,0);
    }, [pathname]);

    const menuItems = [
    {
        key: '',
        label: isAdmin ? "Аккаунты" : "Главная",
    },
    {
        key: 'documents',
        label: "Документы",
    },
    {
        key: 'templates',
        label: "Макеты",
    }]

    function logout() {
        keycloak.logout();
    }

    function toMain() {
        navigate('/'); 
    }

    function toSettings() {
        navigate('/settings');
    }

    function toDocuments() {
        navigate('/documents');
    }

    function toTemplates() {
        navigate('/templates'); 
    }


    function calculateCurrentMenuItem() {
       const currentLocation =  window.location.href;
       return [menuItems.slice(1).find(item => currentLocation.includes(item.key))?.key || ''];
    }

    return <Layout style={{ background: '#FFFFFF' }}>
        <Header
            style={{
                position: 'sticky',
                top: 0,
                zIndex: 2,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.03)'
            }}
        >
            <Flex justify='space-between' align='center' style={{width: '1200px'}}>
                <TextButton type="link" onClick={toMain} style={{fontWeight: 500, height: '64px'}}>PMG Telegram Ads</TextButton>
                <Menu
                    mode="horizontal"
                    selectedKeys={calculateCurrentMenuItem()}
                    items={isAdmin ? menuItems.filter(({key}) => key !== 'documents') : menuItems}
                    onClick={(el) => {
                        navigate(`/${el.key}`)
                    }}
                    style={{
                        minWidth: '200px',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                </Menu>
                <Space size={16} align='center'>
                    <TextButton type="link" onClick={toSettings} style={{border: '1px solid'}} icon={<UserOutlined/>}/>
                    <TextButton type="link" $hoverColor="#FF4D4F" style={{border: '1px solid'}}
                    icon={<LogoutOutlined/>} 
                    onClick={logout} ><span style={{fontSize: '14px'}}>Выйти</span></TextButton>
                </Space>
            </Flex>
        </Header>
        <Content style={{ margin: '24px 24px', padding: 0, minHeight: 'calc(100vh - 112px)', overflow: 'auto' }}>
            {children}
        </Content>
        { !isAdmin && <Footer 
            style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                background:'#E6F4FF',
                height: '288px',
                padding: '48px 40px'
            }}
        >
            <Flex style={{height: '100%', width: '1200px'}} justify='space-between' vertical>
                <Flex justify='space-between'>
                    <Space size={8} direction='vertical'>
                        <Typography.Text style={{fontSize: '24px', fontWeight: 500, lineHeight: '32px'}}>Performance Marketing Group<br/>Telegram Ads</Typography.Text>
                        <Typography.Text>Новый эффективный инструмент<br/>для коммуникации с вашей аудиторией</Typography.Text>
                    </Space >
                    <Flex vertical align='start'>
                        <Space size={24}>
                            <TextButton type="link" onClick={toMain} style={{padding: '4px 16px'}}>Главная</TextButton>
                            <TextButton type="link" onClick={toDocuments} style={{padding: '4px 16px'}}>Документы</TextButton>
                            <TextButton type="link" onClick={toTemplates} style={{padding: '4px 16px'}}>Макеты</TextButton>
                        </Space>
                        <TextButton type="link"
                            style={{
                                color: 'rgba(0, 0, 0, 0.45)', 
                                textDecoration: 'underline'
                            }}>Политика конфиденциальности
                        </TextButton>
                    </Flex>
                    <Space size={24} direction='vertical' align='end'>
                        <Flex>
                            <Button type='link' href='mailto:info@pmg.ai'>info@pmg.ai</Button>
                            <Button type='link' href='tel:+74994443360' style={{paddingRight: 0}}>+7 (499) 444-33-60</Button>
                        </Flex>
                        <div style={{textAlign: 'right', lineHeight: '24px'}}>
                            Россия, 115280, Москва, Ленинская слобода, д. 26,<br/>строение 5,БЦ «Симонов Плаза», 2-й этаж, офис 2406
                        </div>
                    </Space>
                </Flex>
                <Flex justify='space-between'>
                    <Typography.Text style={{color:'rgba(0, 0, 0, 0.45)'}}>2024 © Все права защищены</Typography.Text>
                    <Typography.Text style={{color:'rgba(0, 0, 0, 0.45)'}}>ООО «Перформанс Маркетинг»</Typography.Text>
                </Flex>
            </Flex>
        </Footer>}
    </Layout>
}