import { Button, Checkbox, Col, Divider, Flex, Form, Input, Modal, Popover, Radio, Row, Select, Space, Spin, Tag, Typography } from "antd";
import { ContentLayout } from "../../components/layout/styled";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { Phone } from "../../components/Phone/Phone";
import { useNavigate, useParams } from "react-router";
import { api } from "../../api";
import { ModalContent } from "../../components/ModalContent/ModalContent";
import { CopyOutlined, DownOutlined, LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { SuffixIconWrapper } from './styled';

const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

export function TemplatePage({ mode }: { mode: 'create' | 'view' | 'edit' }) {
    
    const isViewMode = mode === 'view';
    const isCreateMode = mode === 'create';
    const isEditMode = mode === 'edit';

    const navigate = useNavigate()

    const [text, setText] = useState('')
    const [title, setTitle] = useState('')
    const [senderName, setSenderName] = useState('')
    const [url, setUrl] = useState('')
    const [state, setState] = useState()
    const [numberViews, setNumberViews] = useState(0)
    const [topicIds, setTopicIds] = useState<number[]>([])
    const [excludeTopicIds, setExcludeTopicIds] = useState<number[]>([])
    const [form] = Form.useForm();
    const [topicsList, setTopicsList] = useState<{ label: string, value: number }[]>([])
    const [isValid, setIsValid] = useState(false)
    const [isReadyToSave, setIsReadyToSave] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [isWhiteListOpened, setIsWhiteListDropdownOpened] = useState(false);
    const [isBlackListOpened, setIsBlackListDropdownOpened] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        if (!isLoading)
            init()
    }, [])

    useEffect(() => {
        form.setFieldsValue({
            title: title,
            senderName: senderName,
            text: text,
            url: url,
            numberViews: numberViews,
            excludeTopicIds: excludeTopicIds,
            topicIds: topicIds,
        }) 
        checkValidation()
    }, [title, senderName, text, url, state, numberViews, topicIds, excludeTopicIds
    ])

    function checkValidation() {
        let valid = true;
        let readyToSave = true;
        form.getFieldsError().forEach(item => {
            if (item.errors.length) {
                valid = false; 
                readyToSave = false;
            }
        })

        if (title.length == 0 || senderName.length == 0 || text.length == 0 || url.length == 0 || numberViews == 0 || topicIds.length == 0) valid = false;
        setIsValid(valid);
        setIsReadyToSave(readyToSave);
    }

    async function init() {
        setIsLoading(true)
        let topics = await api.getTopics();
        setTopicsList(topics.map((el: any) => ({ label: el.rusName, value: el.topic_id })))
        if (isViewMode || isEditMode) {
            let result = await api.getTemplate(id);
            setExcludeTopicIds(result.excludeTopicIds || [])
            setNumberViews(result.numberViews)
            setSenderName(result.senderName || '')
            setState(result.state)
            setText(result.text || '')
            setTitle(result.title || '')
            setTopicIds(result.topicIds || [])
            setUrl(result.url || '')
        }

        setIsLoading(false)
    }


    async function onFinish() {
        try {  
            await form.validateFields();
            
            let newTemplateId = null;
            if (isCreateMode) {
                newTemplateId = await api.createTemplate({})
            }

            await updateTemplate(newTemplateId || id)
            await api.registerTemplate(newTemplateId || id)
            navigate('/templates')
        } catch (e) {
            checkValidation()
        }
    }

    async function onSave() {
        try {  
            let newTemplateId = null
            if (isCreateMode) {
                newTemplateId = await api.createTemplate({})
            }

            await updateTemplate(newTemplateId || id);
            navigate('/templates')
        } catch (e) {
            checkValidation()
        }
    }

    function updateTemplate(id: string) {
        api.updateTemplate({
            "id": id,
            "title": title,
            "senderName": senderName,
            "text": text,
            "url": url,
            "websiteName": url,
            "state": state,
            "numberViews": numberViews || 1,
            "topicIds": topicIds,
            "excludeTopicIds": excludeTopicIds
        })
    }

    function onFinishFailed() {

    }

    function onChangeWhiteList(newTopicIds: number[]) {
        setTopicIds(newTopicIds);
    }

    function onChangeCheckboxWhiteList({target}: CheckboxChangeEvent) {
        target.checked ? onChangeWhiteList(topicsList.map(topic => topic.value).filter(((topicId) => !excludeTopicIds.includes(topicId)))) 
        : onChangeWhiteList([])
    }

    function onChangeBlackList(newExcludeTopicIds: number[]) {
        setExcludeTopicIds(newExcludeTopicIds);
        topicIds.includes(newExcludeTopicIds[newExcludeTopicIds.length - 1]) &&  setTopicIds(topicIds.filter((topicId) => topicId !== newExcludeTopicIds[newExcludeTopicIds.length - 1]))
    }

    function renderTitle () {
        if (isCreateMode) return "Регистрация макета"
        if (isViewMode) return "Просмотр макета"
        if (isEditMode) return "Изменение макета"        
    }
    
    function renderPopoverContent () {
        return <div>
            <p>Имя отправителя: {senderName}</p>
            <p>Текст: {text}</p>
            <p>Ссылка: {url}</p>
            <p>Frequency: {numberViews}</p>
            <p> White list: {topicsList.filter(({value}) => topicIds.find(topicId => topicId === value))
            .sort((a, b) => a.value - b.value).map(({value, label}) => `${value} ${label}`).join(', ')}</p>
            <p>Black list: {topicsList.filter(({value}) => excludeTopicIds.find(topicId => topicId === value))
            .sort((a, b) => a.value - b.value).map(({value, label}) => `${value} ${label}`).join(', ')}</p>
        </div>

    }

    if (isLoading) {
        return <Flex justify="center" align="center" style={{ height: 'calc(100vh - 120px)' }}>
            <Spin size="large" indicator={antIcon}></Spin>
        </Flex>
    }

    return <ContentLayout>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Space direction="vertical" size={32} style={{ width: '50%', paddingLeft: '60px', paddingRight: '60px' }}>
                <Flex align="center">
                    <Typography.Title level={3} style={{margin: '12px 0'}}>{renderTitle()}</Typography.Title>
                    <Popover content={renderPopoverContent()} placement="bottom" arrow={false} trigger="click" overlayStyle={{ maxWidth: '600px' }}>
                        <Button type="link" icon={<CopyOutlined />} size="large" style={{paddingTop: '8px'}}/>
                    </Popover>
                </Flex>
                <Form
                    form={form}
                    name="template"
                    style={{ width: '100%' }}
                    initialValues={{
                        title,
                        senderName,
                        text,
                        url,
                        numberViews,
                        excludeTopicIds,
                        topicIds
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item
                        name="title"
                        rules={[{ required: true, message: 'Поле не должно быть пустым.' }]}
                    >
                        <Flex vertical gap={8}>
                            <Typography.Title level={5} style={{ margin: 0, fontWeight: 500 }}>Название макета</Typography.Title>
                            <Input placeholder="Имя макета" defaultValue={title} disabled={isViewMode} onChange={(event) => setTitle(event.target.value)} />
                        </Flex>
                    </Form.Item>
                    <Form.Item
                        name="senderName"
                        rules={[{ required: true, message: 'Поле не должно быть пустым.' }, { max: 40, message: 'Сообщение не должно превышать 40 символов.' }]}
                    >
                        <Flex vertical gap={8}>
                            <Typography.Title level={5} style={{ margin: 0, fontWeight: 500 }}>Имя отправителя (заголовок рекламного сообщения)</Typography.Title>
                            <Input placeholder="Имя отправителя" defaultValue={senderName} disabled={isViewMode} 
                            onChange={(event) => {
                                if (event.target.value.length > 40) {
                                    setIsReadyToSave(false)
                                    return;
                                }
                                setSenderName(event.target.value)
                            }}  />
                            {senderName && (40 - senderName.length) >= 0 && <Typography.Text type="secondary">Осталось {(40 - senderName.length)} символов из 40</Typography.Text>}
                        </Flex>
                    </Form.Item>
                    <Form.Item
                        name="text"
                        rules={[{ required: true, message: 'Поле не должно быть пустым.' }, { max: 160, message: 'Сообщение не должно превышать 160 символов.' }]}
                    >
                        <Flex vertical gap={8}>
                            <Row style={{ width: '432px' }} justify='space-between' align='middle'><Col><Typography.Title level={5} style={{ margin: 0, fontWeight: 500 }}>Текст</Typography.Title></Col><Col><Button size="small" type="link" style={{ paddingLeft: 0, paddingRight: 0 }} onClick={() => setIsModalOpen(true)}>Правила оформления текста</Button></Col></Row>
                            <Space direction="vertical" size={4}>
                                <TextArea name="text" defaultValue={text} disabled={isViewMode} 
                                onChange={(event) => {
                                    if (event.target.value.length > 160) {
                                        setIsReadyToSave(false)
                                        return;
                                    }
                                    setText(event.target.value)
                                }} 
                                placeholder="Ваше сообщение, размещенное под сообщениями канала"/>
                                {text && (160 - text.length) >= 0 && <Typography.Text type="secondary">Осталось {(160 - text.length)} символов из 160</Typography.Text>}
                            </Space>
                        </Flex>
                    </Form.Item>
                    <Form.Item
                        name="url"
                        rules={[{ required: true, message: 'Ссылка должна иметь правильный формат.', pattern: new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/) }]}
                    >
                        <Flex vertical gap={8}>
                            <Typography.Title level={5} style={{ margin: 0, fontWeight: 500 }}>Ссылка</Typography.Title>
                            <Input defaultValue={url} disabled={isViewMode} placeholder="https://www.example.com" 
                            onChange={(event) => {
                                if (!event.target.value.match(new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/))) {
                                    setIsReadyToSave(false)
                                    return;
                                }
                                setUrl(event.target.value)               
                            }}/>
                        </Flex>
                    </Form.Item>
                    <Form.Item
                        name="numberViews"
                        rules={[{ required: true, message: '' }]}
                    >
                        <Flex vertical gap={8}>
                            <Typography.Title level={5} style={{ margin: 0, fontWeight: 500 }}>{isViewMode ? `Frequency: ${numberViews}` : 'Frequency'}</Typography.Title>
                            <Space>
                                <Button disabled={isViewMode} size="large" type={numberViews == 1 ? 'primary' : 'default'} style={{ width: 82 }} onClick={() => setNumberViews(1)}>1</Button>
                                <Button disabled={isViewMode} size="large" type={numberViews == 2 ? 'primary' : 'default'} style={{ width: 82 }} onClick={() => setNumberViews(2)}>2</Button>
                                <Button disabled={isViewMode} size="large" type={numberViews == 3 ? 'primary' : 'default'} style={{ width: 82 }} onClick={() => setNumberViews(3)}>3</Button>
                                <Button disabled={isViewMode} size="large" type={numberViews == 4 ? 'primary' : 'default'} style={{ width: 82 }} onClick={() => setNumberViews(4)}>4</Button>
                            </Space>
                        </Flex>
                    </Form.Item>

                    <Form.Item
                        name="topicIds"
                        rules={[{ required: true, message: 'Поле не должно быть пустым.' }]}
                    >
                        <Flex vertical gap={8}>
                            <Typography.Title level={5} style={{ margin: 0, fontWeight: 500 }}>White list</Typography.Title>
                            <Select value={topicIds}
                                disabled={isViewMode}
                                placement="topLeft" 
                                placeholder="Выберите категорию"
                                mode="multiple" 
                                maxTagCount={6}
                                maxTagPlaceholder={(omttedTopics)=><span>+ ещё {omttedTopics.length}</span>}
                                suffixIcon={<SuffixIconWrapper>
                                    {isWhiteListOpened ? <SearchOutlined id="select-icon"/> : <DownOutlined id="select-icon"/>}
                                </SuffixIconWrapper>}
                                onDropdownVisibleChange={(isOpened) => setIsWhiteListDropdownOpened(isOpened)}
                                options={topicsList.filter((el) => !excludeTopicIds?.find(item => item === el.value))} 
                                onChange={onChangeWhiteList} 
                                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())} 
                                dropdownRender={(originalDropdown) => (
                                    <>
                                    {originalDropdown}
                                      <Divider style={{ margin: '8px 0' }} />
                                      <Space style={{ padding: '0 8px 4px' }}>
                                            <Checkbox 
                                            indeterminate={topicIds.length > 0 && topicIds.length < topicsList.length - excludeTopicIds.length}
                                            checked={topicIds.length === topicsList.length - excludeTopicIds.length}
                                            onChange={onChangeCheckboxWhiteList}
                                            >
                                                    Выбрать все
                                            </Checkbox>
                                      </Space>
                                    </>
                                  )}
                            />
                        </Flex>
                    </Form.Item>

                    <Form.Item
                        name="excludeTopicIds"
                    >
                        <Flex vertical gap={8}>
                            <Typography.Title level={5} style={{ margin: 0, fontWeight: 500 }}>Black list</Typography.Title>
                            <Select value={excludeTopicIds}
                                disabled={isViewMode}
                                placement="topLeft" 
                                placeholder="Выберите категорию" 
                                mode="multiple" 
                                maxTagCount={6}
                                maxTagPlaceholder={(omttedTopics)=><span>+ ещё {omttedTopics.length}</span>}
                                suffixIcon={<SuffixIconWrapper>
                                    {isBlackListOpened ? <SearchOutlined id="select-icon"/> : <DownOutlined id="select-icon"/>}
                                </SuffixIconWrapper>}
                                onDropdownVisibleChange={(isOpened) => setIsBlackListDropdownOpened(isOpened)}
                                options={topicsList}
                                onChange={onChangeBlackList}
                                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                            />
                        </Flex>
                    </Form.Item>

                    <Form.Item hidden={isViewMode}>
                        <Button 
                        disabled={!isReadyToSave}   
                        size="large" 
                        onClick={onSave}
                        style={{width: '100%' }}
                        >
                            Сохранить
                        </Button>
                    </Form.Item>
                    <Form.Item hidden={isViewMode}>
                        <Button 
                        disabled={!isValid}   
                        type="primary" htmlType="submit" size="large" 
                        style={{width: '100%' }}
                        >
                            Отправить
                        </Button>
                    </Form.Item>
                </Form>
            </Space>
            <div>
                <Phone text={text || 'Текст'} senderName={senderName || 'Имя отправителя'}></Phone>
            </div>
        </div>
        <Modal footer={<Button type="primary" onClick={() => setIsModalOpen(false)}>Понятно</Button>} title="Правила размещения рекламных сообщений в Telegram" open={isModalOpen} onCancel={() => setIsModalOpen(false)} width='760px'>
            <ModalContent/>
        </Modal>
    </ContentLayout >
}