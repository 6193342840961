import React, { useEffect, useState } from 'react';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { DocumentsPage } from './views/Documents/Documents';
import { TemplatesPage } from './views/Templates/Templates';
import { TemplatePage } from './views/Templates/Template';
import { MainLayout } from './components/layout/MainLayout';
import { ConfigProvider } from 'antd';
import { api } from './api';
import { AccountsPage } from './views/AccountsPage';
import { SettingsPage } from './views/SettingsPage';
import MainPage from './views/MainPage/MainPage';

export interface IUser {
  username?: string,
  fullName?: string,
  email?: string,
  phoneNumber?: string,
  country?: string,
  city?: string,
  legalName?: string
  contactApproved?: boolean
  authorities?: { authority: string }[]
}

function App() {

  const [user, setUser] = useState<IUser>()

  useEffect(() => {
    initUser()
  }, [])

  async function initUser() {
    let res = await api.getUser()
    console.log(res)
    setUser(res)
  }

  function isAdmin() {
    return user?.authorities?.find(i => i.authority == "ROLE_ADMIN") ? true : false
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: '#ffffff',
            headerSplitColor: '#ffffff'
          },
          Layout: {
            headerBg: '#ffffff'
            
          }
        },
        token: {
          fontWeightStrong: 700,
          fontSize: 16
        }
      }}
    >
      <BrowserRouter>
        <MainLayout email={user?.email} isAdmin={isAdmin()}>
          <Routes>
            <Route path="/" element={isAdmin() ? <AccountsPage /> : <MainPage />}></Route>
            {
              !isAdmin() && <Route path="/documents" element={<DocumentsPage isApproved={user?.contactApproved} />}></Route>
            }
            <Route path="/templates" element={<TemplatesPage isAdmin={isAdmin()} />}></Route>
            <Route path="/templates/:id/edit" element={<TemplatePage mode='edit'/>}></Route>
            <Route path="/templates/:id/view" element={<TemplatePage mode='view'/>}></Route>
            <Route path="/templates/create" element={<TemplatePage mode='create'/>}></Route>
            <Route path="/settings" element={<SettingsPage />}></Route>
          </Routes>
        </MainLayout>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
