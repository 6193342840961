import { useEffect, useState } from "react";
import { ContentLayout } from "../components/layout/styled";
import { api } from "../api";
import { Flex, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Settings } from "../components/AccountSettings/Settings";
import { IUser } from "../App";
const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;


export function SettingsPage() {

    const [user, setUser] = useState<IUser>({})
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        init()
    }, [])

    async function init() {
        let result = await api.getUser()
        setIsLoading(false)
        setUser(result)
    }

    if (isLoading) {
        return <Flex justify="center" align="center" style={{ height: 'calc(100vh - 120px)' }}>
            <Spin size="large" indicator={antIcon}></Spin>
        </Flex>
    }

    return <ContentLayout>
        <Settings user={user}/>
    </ContentLayout>
}