import { Tabs, TabsProps, Typography } from "antd";
import { FirstTab } from "./tabs/First";
import { SecondTab } from "./tabs/Second";
import { ThirdTab } from "./tabs/Third";
import { FourthTab } from "./tabs/Fourth";
import { FifthTab } from "./tabs/Fifth";
import { SixthTab } from "./tabs/Sixth";

export function ModalContent() {


    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Бизнес, товары и услуги',
            children: <FirstTab></FirstTab>,
        },
        {
            key: '2',
            label: 'Содержание',
            children: <SecondTab></SecondTab>,
        },
        {
            key: '3',
            label: 'Посадочная',
            children: <ThirdTab></ThirdTab>,
        },
        {
            key: '4',
            label: 'Тексты',
            children: <FourthTab></FourthTab>,
        },
        {
            key: '5',
            label: 'Кнопка',
            children: <FifthTab></FifthTab>,
        },
        {
            key: '6',
            label: 'Примеры',
            children: <SixthTab></SixthTab>,
        },
    ];

    return <>
        <Typography.Text>Обозначения:</Typography.Text><br></br>
        <Typography.Text>🚫 Совсем нельзя! Нарушение этого правила модерация не пропустит</Typography.Text><br></br>
        <Typography.Text>🔶 Допустимо при определенных условиях. Модерация будет рассматривать и оценивать ваш бизнес, канал и рекламу в комплексе</Typography.Text><br></br>
        <Typography.Text>✅ Разрешено. Используйте, чтобы пройти модерацию</Typography.Text><br></br>
        <Tabs defaultActiveKey="1" items={items} />
    </>
}