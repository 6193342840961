import { useEffect, useState } from "react";
import { Accounts } from "../components/Accounts/Accounts";
import { api } from "../api";
import { Flex, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

export function AccountsPage() {
    const [isLoading, setIsLoading] = useState(true)
    const [accounts, setAccounts] = useState([])

    useEffect(() => {
        init()
    }, [])

    async function init() {
        let result = await api.getAccounts()
        setIsLoading(false)
        setAccounts(result.content)
    }

    if (isLoading) {
        return <Flex justify="center" align="center" style={{ height: 'calc(100vh - 120px)' }}>
            <Spin size="large" indicator={antIcon}></Spin>
        </Flex>
    }


    return <Accounts items={accounts} update={init}></Accounts>
}