import Keycloak from 'keycloak-js';
declare var KEYCLOAK_URL: string
declare var KEYCLOAK_REALM: string
declare var CLIENT_ID: string

export const keycloak = new Keycloak({
    url: KEYCLOAK_URL,
    realm: KEYCLOAK_REALM,
    clientId: CLIENT_ID,
});
