import { Space } from "antd";
import styled from "styled-components";

export const Wrapper = styled(Space)`
display: flex;
background-color: #ffff;
width: 1200px;
height: 100%;
margin: 40px auto;
`

export const CustomImage = styled.img`
  width: 57%;
  height: auto;
  position: absolute;
  right: 0;
`
