import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Button, Input, Space } from 'antd';
import type { ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { ITemplate } from './typed';




export const getColumnSearchProps = ( //TODO: сделать reuseble??? 
  searchInput: React.RefObject<InputRef>,
  dataIndex: keyof ITemplate, 
  handleSearch: (
    confirm: (param?: FilterConfirmProps) => void,
  ) => void,
): ColumnType<ITemplate> => { 

  return {

    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(confirm)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(confirm)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) => (record[dataIndex] ? 
      record[dataIndex]
        .toLowerCase()
        .includes((value as string).toLowerCase()) 
        : false
    )
      ,
  } 
};
