import { Typography } from "antd";

export function SecondTab() {

    return <>
        <Typography.Text>
            Содержание в канале, постах, объявлениях и других местах размещения<br></br>
            <br></br>
            🚫 Шокирующий контент<br></br>
            Визуально шокирующий контент, демонстрация последствий жестокости, насилия, травм и несчастных случаев<br></br>
            <br></br>
            🚫 Агрессивная реклама<br></br>
            Упоминания трагедий, кризисов или актов массового насилия в целях привлечения внимания<br></br>
            <br></br>
            🚫 Ненависть, насилие, домогательства, дискриминация<br></br>
            Дискриминация лиц или групп по признакам расы, религии, пола и т. д.<br></br>
            <br></br>
            🚫 Нарушение прав третьих лиц<br></br>
            Плагиат или несанкционированное распространение контента, защищенного авторским правом, раскрытие личных данных (например, контакты, адреса), нарушение авторских прав (например, реклама защищенных фильмов, книг), использование логотипов известных брендов и т. д.<br></br>
            <br></br>
            🚫 Кликбейт или реклама, вводящая в заблуждение<br></br>
            Нереалистичные заявления, преувеличенные сравнения и абсолютные утверждения, использование приманок («Поздравляем, только что вы выиграли»), безосновательных преувеличений («У нас самые высокие доходы»), перехода на личности («Мы знаем, что вы и ваши друзья...»).<br></br>
            <br></br>
            🚫 Выделение части аудитории и обращение к ней<br></br>
            Нельзя выделять какую-либо группу пользователей по признаку и обращаться только к ней. Например: “Только для жителей Москвы!”, “Канал для девушек”, “Только мужские тайны”.<br></br>
            <br></br>
            🚫 Акцент на травмирующих и значимых событиях<br></br>
            Нельзя использовать для привлечения внимания, что потенциально могло ранить пользователя (развод, конец отношений, болезнь, смерть, военные конфликты, кризисы, катастрофы).<br></br>
            <br></br>
            ✅ Полезный экспертный контент<br></br>
            То, что будет интересно пользователю<br></br>
            <br></br>
            ✅ Интерактивный контент<br></br>
            Форматы Telegram, которые повышают вовлеченность пользователя, с учетом соблюдения всех норм по содержанию<br></br>
        </Typography.Text>
    </>
}