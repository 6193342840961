import { useEffect, useState } from "react";
import { TemplateEmptyState } from "../../components/TemplateList/TemplateEmptyState";
import { TemplateList } from "../../components/TemplateList/TemplateList";
import { ContentLayout } from "../../components/layout/styled";
import { api } from "../../api";
import { Flex, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;


export function TemplatesPage({ isAdmin }: { isAdmin: boolean }) {

    const [templates, setTemplates] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        init()
    }, [])

    async function init() {
        let result = await api.getTemplates()
        setIsLoading(false)
        setTemplates(result.content)
    }

    if (isLoading) {
        return <Flex justify="center" align="center" style={{ height: 'calc(100vh - 120px)' }}>
            <Spin size="large" indicator={antIcon}></Spin>
        </Flex>
    }

    if (templates.length)
        return <ContentLayout>
            <TemplateList items={templates} refresh={init} isAdmin={isAdmin}></TemplateList>
        </ContentLayout>

    return <ContentLayout>
        <TemplateEmptyState></TemplateEmptyState>
    </ContentLayout>
}