import { Typography } from "antd";

export function SixthTab() {

    return <>
        <Typography.Text>
            Примеры креативов для разных индустрий<br></br>
            <br></br>
            B2B<br></br>
            🚫 Доски из ДПК, сертифицированы ИНЭОС РАН, от производителя ООО “Лучшие Доски”, с эффектом “Антислип”.<br></br>
            ✅ В боте @RAN поможем подобрать террасные доски для вашего проекта и рассчитаем стоимость материалов.<br></br>
            <br></br>
            Финансы и инвестиции<br></br>
            🚫 Расскажем, как без риска и вложений получать доходность до 30% от инвестиций<br></br>
            ✅ Рассказываем в нашем канале, какие стратегии инвестирования есть и как выбрать подходящую для себя<br></br>
            🚫 Антикризисные идеи от топовых экспертов фондового рынка в Telegram канале.<br></br>
            ✅ Делимся антикризисными инвестиционными стратегиями в нашем канале<br></br>
            <br></br>
            Маркетинг<br></br>
            🚫 Эй, маркетолог, чего ждешь? Заходи в канал о том, как самостоятельно создавать чат-боты и воронки продаж в мессенджерах.<br></br>
            ✅ Рассказываем о том, как самостоятельно создавать чат-боты и воронки продаж в мессенджерах<br></br>
            🚫 Пройди короткий опрос в нашем боте и узнай, как привлекать и удерживать клиентов<br></br>
            ✅ Способы привлечения и удержания клиентов показываем в нашем боте<br></br>
            <br></br>
            Недвижимость<br></br>
            🚫 Уже надоело искать квартиру? Заходи за квартирой в канал @channel<br></br>
            ✅ Выбрать квартиру сложно, но реально. Наш канал помогает с выбором, каждый день публикует доступные и выгодные квартиры Петербурга.<br></br>
            <br></br>
            Образование<br></br>
            🚫 Вашего ребенка бьют в школе? Расскажем, как с этим справиться, в канале @channel<br></br>
            ✅ 6 гайдов для мам про повышение интереса к учебе, снижение влияние гаджетов, способность постоять за себя и развитие гибких навыков детей от 6 до 14 лет.<br></br>
            <br></br>
            Каналы экспертов, инфобиз<br></br>
            🚫 Ничего не знаешь про маркетинг? Научу в канале @channel<br></br>
            ✅ Канал для тех, кто любит маркетинг и хочет в нем разбираться @channel<br></br>
            ✅ Лайфхаки по маркетингу для малого бизнеса. Кейсы и инструкции бесплатно на канале @channel<br></br>
            ✅ Как создать воронку продаж с дедлайнами для участников и ключевыми инструментами? Рассказывает продюсер @channel<br></br>
            ✅ Делюсь личным опытом привлечения клиентов в текущих реалиях. Подходит для проектов любого размера.<br></br>
            ✅ Рассказываю о том, как начать печь десерты на заказ в своём городе @channel<br></br>
            <br></br>
            Медицина<br></br>
            🚫 Как сохранить мужскую силу? Как улучшить качество сексуальной жизни? Об этом и не только рассказывают ведущие урологи, андрологи, психологи.<br></br>
            🚫 В канале @channel информируем, как вылечить любое заболевание без лекарств с помощью НазваниеТовара.<br></br>
            ✅ Многопрофильный амбулаторно-госпитальный центр в 9 км от МКАД. Все виды обследований. Врачи с квалификацией не ниже доктора наук.<br></br>
            ✅ Полезные лекции о детском здоровье в официальном канале доктора ИмяФамилия @channel<br></br>
            <br></br>
            Мода и стиль<br></br>
            🚫 Нечего надеть? Что, опять?! Подписывайся на канал @channel, там научат правильно работать с гардеробом<br></br>
            ✅ Собираем для вас готовые образы на каждый день, рассказываем о новостях мира моды, объясняем тренды в канале @channel<br></br>
            ✅ В канале @channel расскажут про все российские бренды.<br></br>
            ✅ Рассказываем о новостях мира моды, объясняем тренды в канале @channel<br></br>
            <br></br>
            Фитнес и спорт<br></br>
            🚫 Хочешь похудеть? Разыгрываем доступ к онлайн-платформе для тренировок в канале @channel. Успей до 20 апреля!<br></br>
            ✅ Бесплатные тренировки для дома на канале @channel. Всего 20 минут занятий для здорового и подтянутого тела.<br></br>
            <br></br>
            FMCG<br></br>
            🚫 То чувство, когда есть канал со скидками на технику для дома, а ты еще не подписался. Скорее заходи в @channel<br></br>
            ✅ Товары можно купить выгодно. В канале маркетплейса @channel и скидки, и обзоры на товары.<br></br>
            ✅ Как найти хорошую мебель и технику для своей кухни? Рассказываем на канале @channel.<br></br>
            <br></br>
            IT-компании<br></br>
            🚫 Как сделать уникальный виз из зеро блоков с прогресс баром и кнопками<br></br>
            ✅ Канал про создание сайтов. Рассказываем о полезных фичах и инструментах<br></br>
        </Typography.Text>
    </>
}