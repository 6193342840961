import styled, { css } from "styled-components";

export const StateCircle = styled.span<{ $success?: boolean; }>`
    height: 8px;
    width: 8px;
    background-color: #FF0000;
    border-radius: 50%;
    display: inline-block;

    ${props => props.$success && css`
    background-color: #52C41A;
  `}
`