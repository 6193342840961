import { Button, Flex, Modal, Space, Typography } from "antd";
import { Wrapper } from "./styled";
import { EyeOutlined } from "@ant-design/icons";
import { ModalContent } from "../../components/ModalContent/ModalContent";
import { useState } from "react";
import { Card, H1, H2, H3, TransparentButton } from "../../commonStyled";
import { SigningSteps } from "../../components/SigningSteps/SigningSteps";
import { TemplateRegistrationSteps } from "../../components/TemplateRegistrationSteps/TemplateRegistrationSteps";

export function DocumentsPage({ isApproved }: { isApproved?: boolean }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return <Wrapper>
        <Space direction='vertical' size={64}>
            <Space direction='vertical' size={32}>
                <H1>Мои документы</H1>
                <Space size={24}>
                    <Space direction='vertical' size={24}>
                        <H3>Текущий статус подписания</H3>
                        <Card style={{width: '588px'}}>
                            {isApproved ? 
                            <Space direction='vertical' size={24}>
                                <Flex align='center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16Z" fill="#52C41A"/>
                                    </svg>
                                    <H2 style={{marginLeft: '12px'}}>Документ подписан</H2>
                                </Flex>
                                <Typography.Text>Макеты можно зарегистрировать</Typography.Text>
                            </Space> :
                            <Space direction='vertical' size={24}>
                                <Flex align='center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <path d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16Z" fill="#F5222D"/>
                                    </svg>
                                    <H2 style={{marginLeft: '12px'}}>Ожидание подписания документа</H2>
                                </Flex>
                                <Typography.Text>Без подписанного договора макеты нельзя отправить на регистрацию</Typography.Text>
                            </Space>}
                        </Card>
                    </Space>
                    <Space direction='vertical' size={24}>
                        <H3>Инструкции</H3>
                        <Card style={{width: '588px'}}>
                            <Space direction='vertical' size={28}>
                                <Typography.Text style={{fontWeight: 500}}>Правила оформления текста</Typography.Text>
                                <TransparentButton icon={<EyeOutlined />} onClick={() => setIsModalOpen(true)}>Открыть</TransparentButton>
                            </Space>
                        </Card>
                    </Space>
                </Space>
            </Space>
            <SigningSteps />
            <TemplateRegistrationSteps />
        </Space>
        <Modal footer={<Button type="primary" onClick={() => setIsModalOpen(false)}>Понятно</Button>} title="Правила размещения рекламных сообщений в Telegram" open={isModalOpen} onCancel={() => setIsModalOpen(false)} width='760px'>
            <ModalContent/>
        </Modal>
    </Wrapper>
}