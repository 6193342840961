import { Button } from 'antd'
import styled, { css } from 'styled-components'

export const ContentLayout = styled.div`
    background: #FFFFFF;
    width: calc(1200px - 38px);
    min-width: calc(1200px - 38px);
    min-height: calc(100vh - 112px);
    padding: 24px;
    overflow: hidden;
    align-self: center;
    margin: auto;
`

export const TextButton = styled(Button)<{ $hoverColor?: string; }>`
display: flex;
justify-content: center;
align-items: center;
color: black;
border-radius: 8px;
&:hover {
  color: ${({$hoverColor})  => $hoverColor && css`${$hoverColor} !important` || '#1677FF !important'};
}
`