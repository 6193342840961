import { Typography } from "antd";

export function FifthTab() {

    return <>
        <Typography.Text>
            Настройка кнопки в объявлении (ссылки)<br></br>
            <br></br>
            ✅ Внешняя ссылка (на сайт, на скачивание мобильного приложения)<br></br>
            <br></br>
            🚫 Дополнительное внимание к кнопке<br></br>
            Нельзя использовать специальные символ и эмоджи перед кнопкой, которые будут являться для пользователя призывом кликнуть на нее.<br></br>
            <br></br>
            ✅ Параметры старта для бота<br></br>
            Можно добавить к ссылке на бот любой параметр запуска (например, / start или /help)<br></br>
        </Typography.Text>
    </>
}