import type { ColumnsType, FilterConfirmProps } from 'antd/es/table/interface';
import { Button, Space, Table, Tag, message, Popconfirm, Typography, Flex, Dropdown, InputRef } from "antd";
import { DeleteOutlined, DownOutlined, EditOutlined, ExclamationCircleFilled, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../api";
import { getColumnSearchProps } from './getColumnSearchProps';
import { ITemplateStateMenuItem, ITemplate } from './typed';


export function TemplateList({ items, isAdmin, refresh }: { items: any[], isAdmin: boolean, refresh(): void }) {
    const navigate = useNavigate()
    const searchInput = useRef<InputRef>(null);
    const handleSearch = (confirm: (param?: FilterConfirmProps) => void) => confirm();
    const isViewMode = (state: string): boolean => state !== "Создан";  
    
    const calculateStatusTagColor = (status: string): string => {
        switch (status) {
            case "Ожидает отправки":
            case "Отправлен":
            case "Согласован":
                return "success"

            case "Не отправлен":
            case "Отклонен":
                return "error"
            
            default: 
                return "default"
        }
    }

    const templateStateMenuItems: ITemplateStateMenuItem[] = [
        {
            key: 0,
            label: "Создан"
        },
        {
            key: 1,
            label: "Ожидает отправки"
        },
        {
            key: 2,
            label: "Отправлен"
        },
        {
            key: 3,
            label: "Не отправлен"
        },
        {
            key: 4,
            label: "Согласован"
        },
        {
            key: 5,
            label: "Отклонен"
        },
    ]

    const columns: ColumnsType <ITemplate> = [
        {
            title: 'Статус',
            dataIndex: 'state',
            key: 'state',
            filters: templateStateMenuItems.map(({label}) => ({value: label, text: label})),
            onFilter: (value: React.Key| boolean, record: ITemplate) => record.state === value,
            render: (text: string) => <Tag color={calculateStatusTagColor(text)} style={{whiteSpace: 'pre-wrap', width: 'min-content', textAlign: 'center' }}>{text}</Tag>,
            width:'100px'
        },
        {
            title: 'Название макета',
            dataIndex: 'title',
        },
        {
            title: 'Имя отправителя',
            dataIndex: 'senderName',
            ...getColumnSearchProps(searchInput,'senderName', handleSearch),
        },
        {
            title: 'Текст',
            dataIndex: 'text',
            ellipsis: true,
        },
        {
            title: 'Ссылка',
            dataIndex: 'url',
            ellipsis: true,
            render: (text: string) => text ? <a href={text} target="_blank" rel="noopener noreferrer">{text}</a> : null
        },
        {
            title: 'Действия',
            key: 'actions',
            width: "200px",
            render: (text: string, {patternId, state}: ITemplate) => isAdmin 
            ? <Flex align='center'>
                <Link to={`/templates/${patternId}/view`}><Button type="link" icon={ <EyeOutlined /> } /></Link>
                  <Dropdown menu={{ onClick: (item: any) => handleMenuClick(patternId, item.key), items: templateStateMenuItems }}>
                    <Typography.Link>
                        <Flex justify="begin">
                            <span style={{whiteSpace: 'nowrap'}}>{[state]}</span>
                            <DownOutlined style={{paddingLeft: "4px"}} />
                        </Flex>
                    </Typography.Link>
                </Dropdown>   
            </Flex>
            : <Space>
                { isViewMode(state) 
                ? <Link to={`/templates/${patternId}/view`}><Button type="link" icon={ <EyeOutlined /> } /></Link> 
                : <Link to={`/templates/${patternId}/edit`}><Button type="link" icon={ <EditOutlined /> } /></Link>  
                }
                <Popconfirm
                    title="Удалить макет?"
                    description={<Typography>После удаления восстановить <br></br> макет будет невозможно</Typography>}
                    onConfirm={() => confirm(patternId)}
                    okText="Да, удалить"
                    cancelText="Нет"

                    icon={<ExclamationCircleFilled style={{ color: '#FF4D4F' }} />}

                >
                    <Button type="link" icon={<DeleteOutlined />} />
                </Popconfirm>

            </Space>
        },

    ];
    

    const confirm = async (id: string) => {
        await api.deleteTemplate(id);
        message.success('Макет удален');
        refresh();
    };
    
    async function handleMenuClick(patternId: string, key: number) {
        await updateAccount(patternId, key)
        refresh();
    }

    async function updateAccount(id: string, state: number) {
        await api.updateState(
            {
                "patternId": id,
                "state": state
            })
    }

    return <Space size={8} direction='vertical'> 
        {!isAdmin ? <Flex justify="start">
            <Button type="primary" icon={<PlusOutlined />} onClick={() => navigate(`/templates/create`)}>Создать макет</Button>
        </Flex> : null}
        <Table style={{border: '1px solid rgba(0,0,0,0.15)', borderRadius: '8px'}}
        dataSource={items.map((el) => ({key: el.patternId, ...el}))} columns={columns} 
        pagination={{position: isAdmin ? ["topCenter", "bottomCenter"] : ["bottomCenter"]}}/>
    </Space>
}
