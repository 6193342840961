import Keycloak from 'keycloak-js'
import { keycloak } from '../keycloack';

declare var API_URL: string


export const api = {
    async createTemplate(params: any) {
        let isExpired = await keycloak.isTokenExpired()
        if (isExpired) return refreshToken(() => api.createTemplate(params))
        let token = keycloak.token;

        let result = await fetch(`${API_URL}/api/patterns`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })

        return await result.json()

    },
    async updateTemplate(params: any) {
        let isExpired = await keycloak.isTokenExpired()
        if (isExpired) return refreshToken(() => api.updateTemplate(params))
        let token = keycloak.token;

        let result = await fetch(`${API_URL}/api/patterns`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })

        return await result.json()

    },
    async updateAccount(params: any) {
        let isExpired = await keycloak.isTokenExpired()

        if (isExpired) return refreshToken(() => api.updateAccount(params))
        let token = keycloak.token;

        let result = await fetch(`${API_URL}/api/admin/account-settings`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })

        return;

    },

    async getTemplate(id?: string) {
        let isExpired = await keycloak.isTokenExpired()
        if (isExpired) return refreshToken(() => api.getTemplate(id))
        let token = keycloak.token;

        let result = await fetch(`${API_URL}/api/patterns/${id}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })

        return await result.json()

    },
    async registerTemplate(id?: string) {
        let isExpired = await keycloak.isTokenExpired()
        if (isExpired) return refreshToken(() => api.registerTemplate(id))
        let token = keycloak.token;
        let result = await fetch(`${API_URL}/api/patterns/register/${id}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })

        return true

    },
    async updateState(params: any) {
        let isExpired = await keycloak.isTokenExpired()
        if (isExpired) return refreshToken(() => api.updateState(params))
        let token = keycloak.token;
        let result = await fetch(`${API_URL}/api/patterns/updateState`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })

        return true

    },

    async deleteTemplate(id?: string) {
        let isExpired = await keycloak.isTokenExpired()
        if (isExpired) return refreshToken(() => api.deleteTemplate(id))
        let token = keycloak.token;

        let result = await fetch(`${API_URL}/api/patterns/${id}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })

        return true
    },
    async getTopics() {
        let isExpired = await keycloak.isTokenExpired()
        if (isExpired) return refreshToken(() => api.getTopics())
        let token = keycloak.token;

        let result = await fetch(`${API_URL}/api/pattern-topics`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })

        return await result.json()

    },

    async getTemplates(
        page: number = 0,
        size: number = 100,
        asc: boolean = false) {
        let isExpired = await keycloak.isTokenExpired()
        if (isExpired) return refreshToken(() => api.getTemplates())
        let token = keycloak.token;

        let result = await fetch(`${API_URL}/api/patterns/getPage?page=${page}&size=${size}&asc=${asc}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })

        return await result.json()

    },

    async getAccounts(
        page: number = 0,
        size: number = 100,
        asc: boolean = false) {
        let isExpired = await keycloak.isTokenExpired()
        if (isExpired) return refreshToken(() => api.getAccounts())
        let token = keycloak.token;

        let result = await fetch(`${API_URL}/api/admin/account-settings/getPage?page=${page}&size=${size}&asc=${asc}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })

        return await result.json()

    },
    async getUser() {
        let isExpired = await keycloak.isTokenExpired()
        if (isExpired) return refreshToken(() => api.getUser())
        let token = keycloak.token;

        let result = await fetch(`${API_URL}/api/users`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        })

        return await result.json()

    }
}


async function refreshToken(callback: any) {
    let result = await keycloak.updateToken(5)

    if (!result) {
        keycloak.logout()
        return
    }

    return callback()
}
