import { FullscreenOutlined } from "@ant-design/icons";
import { CustomImage} from "./styled";
import { Flex, Space, Typography } from 'antd';
import { useNavigate } from "react-router";
import macbook from '../../assets/macbook.png'
import { BlackButton } from "../../commonStyled";

export default function ServiceDescription({height}: {height: number}) {
    const navigate = useNavigate();
    
    return <Flex align='center'style={{height: height + 'px'}}>
        <Space direction="vertical" size={32}>
            <Space direction="vertical" size={16}>
                <Space size={8}>
                    <FullscreenOutlined  style={{fontSize: '20px', color: "#1677FF"}} />
                    <Typography.Text>НАДЕЖНЫЙ ИНСТРУМЕНТ ДЛЯ ВАШЕЙ РАБОТЫ</Typography.Text>
                </Space>
                <Typography.Text style={{fontSize: '64px', lineHeight: '72px', fontWeight: 700}}>Сервис<br/>для запуска<br/>рекламы<br/>в Telegram Ads</Typography.Text>
                <Typography.Text style={{lineHeight: '24px', color: 'rgba(0, 0, 0, 0.65)'}}>Изучите простой способ заведения рекламы<br/>в новом инструменте</Typography.Text>
            </Space>
            <BlackButton size='large' style={{padding: '4px 24px'}} onClick={() => navigate(`/templates/create`)}>Создать макет</BlackButton>
        </Space>
        <CustomImage src={macbook}></CustomImage>
    </Flex>
    
}